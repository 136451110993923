import { updateSwitchToPage, updateVerificationStatus } from '@features/auth/store/auth.reducer';
import { getCurrentUser } from '@features/auth/store/auth.selectors';
import { IChampionshipStatus } from '@features/championships/models/championship-status.model';
import IconCanceled from '@features/common/components/IconCanceled';
import IconClock from '@features/common/components/IconClock';
import IconClockViewPage from '@features/common/components/IconClockViewPage';
import IconLock from '@features/common/components/IconLock';
import IconLockTournaments from '@features/common/components/IconLockTournaments';
import IconMaxParticipantsReachedTournamentBox from '@features/common/components/IconMaxParticipantsReachedTournamentBox';
import IconMaxParticipantsReachedView from '@features/common/components/IconMaxParticipantsReachedView';
import RegistrationTournamentModal from '@features/common/components/RegistrationTournamentModal';
import { showToast, togglePlayNowModal } from '@features/common/store/common.reducer';
import LoadingIndicator from '@features/layout/components/LoadingIndicator';
import { onsiteNotificationReceivedLocal } from '@features/onsiteNotifications/store/onsiteNotifications.reducer';
import { parseError } from '@http/httpHelpers';
import { unwrapResult } from '@reduxjs/toolkit';
import { useAppDispatch } from '@store/store';
import axios from 'axios';
import classNames from 'classnames/dedupe';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { TournamentAccess } from '../models/tournament-access-type';
import { TournamentStatusIdTypes } from '../models/tournament-status.model';
import { ITournamentType } from '../models/tournament-type.model';
import { ITournamentCompact, TournamentTypes } from '../models/tournament.model';
import { emitHttpRegisterForTournament } from '../store/tournaments.actions';

interface ITournamentRegistrationButtonProps {
	tournamentId: string;
	tournamentStatusId: TournamentStatusIdTypes | IChampionshipStatus['id'];
	isParticipantRegistered: ITournamentCompact['registered'];
	tournamentAccess: TournamentAccess;
	tournamentName: string;
	isTournamentView: boolean;
	isMaxParticipantsLimit: boolean;
	typeTournament?: TournamentTypes;
	latestActiveCopyId?: string;
	gamyType?: ITournamentType;
	theme?: string;
	championshipStatusId?: IChampionshipStatus['id'] | null | undefined;
	championshipId?: string | null;
}

interface ITournamentMaxParticipantsProps {
	isTournamentView: boolean;
	textName?: string;
	typeTournament?: TournamentTypes;
}

const MaxParticipantsParagraphStyle = ({ isTournamentView, textName }: ITournamentMaxParticipantsProps) => {
	const { formatMessage } = useIntl();

	return (
		<p
			className={classNames('', {
				'font-medium leading-[15px] text-backgroundGray-400 xs:text-sm text-xs': !isTournamentView,
				'font-normal leading-[16px] text-white xs:ml-2 ml-[0.3125rem] text-sm': isTournamentView,
			})}
			data-testid='tournamentParticipantLimitReachedMessage'
		>
			{formatMessage({ id: `${textName}` })}
		</p>
	);
};

const TournamentMaxParticipants = ({ isTournamentView, typeTournament }: ITournamentMaxParticipantsProps) => {
	const getShouldShowLongerMessage = () => {
		return typeTournament === 'registeredTournament' || typeTournament === 'defaultTournaments';
	};

	if (isTournamentView) {
		return (
			<div className='bg-backgroundGray-400 py-3 xs:pl-5 pl-1 w-full flex lg:rounded-[0.1875rem]'>
				<IconMaxParticipantsReachedView />
				<MaxParticipantsParagraphStyle
					isTournamentView={isTournamentView}
					textName={'bcs-Registration-Participant-Limit'}
				/>
			</div>
		);
	} else {
		return (
			<>
				<div className='min-w-[1.1875rem] min-h-[1.1875rem] mr-2.5 ml-0.5 xs:flex hidden items-center'>
					<IconMaxParticipantsReachedTournamentBox />
				</div>
				<div className={`${getShouldShowLongerMessage() ? '' : 's:block hidden'}`}>
					<MaxParticipantsParagraphStyle
						isTournamentView={isTournamentView}
						textName={'bcs-Registration-Participant-Limit'}
					/>
				</div>
				<div className={`${getShouldShowLongerMessage() ? 'hidden' : 's:hidden block'}`}>
					<MaxParticipantsParagraphStyle
						isTournamentView={isTournamentView}
						textName={'bcs-Registration-Participant-Limit-Small-Mobile-Screen'}
					/>
				</div>
			</>
		);
	}
};

const TournamentRegistrationButton = (props: ITournamentRegistrationButtonProps) => {
	const {
		tournamentId,
		tournamentStatusId,
		isParticipantRegistered,
		tournamentAccess,
		tournamentName,
		typeTournament,
		isTournamentView,
		latestActiveCopyId,
		isMaxParticipantsLimit,
		gamyType,
		theme,
		championshipStatusId,
		championshipId,
	} = props;
	const dispatch = useAppDispatch();
	const router = useRouter();
	const { formatMessage } = useIntl();
	const currentUser = useSelector(getCurrentUser);
	const [isRegisterLoading, setIsRegisterLoading] = useState(false);
	const [isCodeModalVisible, setIsCodeModalVisible] = useState(false);

	const [isRequestSent, setIsRequestSent] = useState<boolean>(false);

	const onClickRegistrationButton = () => {
		let message: string;
		let button: string;

		if (!currentUser) {
			dispatch(updateVerificationStatus('Unknown User'));
			dispatch(updateSwitchToPage('Create Account'));
			return;
		}

		if (tournamentAccess === 'QR_SCAN') {
			setIsCodeModalVisible(true);
			return;
		}

		setIsRegisterLoading(true);
		dispatch(emitHttpRegisterForTournament({ tournamentId, terms: true }))
			.then(unwrapResult)
			.then(() => {
				dispatch(
					onsiteNotificationReceivedLocal({
						id: tournamentId,
						content: '',
						dateReceived: new Date().toISOString(),
						eventObjectId: tournamentId,
						imageId: '',
						template: '',
						type: 'LOCAL',
					})
				);
			})
			.catch((error) => {
				const parsedError = parseError(error);
				switch (parsedError.code) {
					case 'INVALID_PARAMETER':
						message = formatMessage({
							id: 'bcs-Tournament-RegistrationPeriodHasEnded',
						});
						break;
					case 'PARTICIPANT_LIMIT_REACHED':
						message = formatMessage({
							id: 'bcs-Registration-Participant-Limit',
						});
						break;
					case 'INVALID_FIELDS':
						message = formatMessage({
							id: 'bcs-Registration-Forbidden',
						});
						button = formatMessage({
							id: 'bcs-Common-GotIt',
						});
						break;
					case 'FORBIDDEN':
						message = formatMessage({
							id: 'bcs-Registration-Already-Registered',
						});
						button = formatMessage({
							id: 'bcs-Common-GotIt',
						});
						break;
					case 'NO_PERMISSION':
						message = formatMessage({
							id: 'bcs-Registration-No-Permission',
						});
						button = formatMessage({
							id: 'bcs-Common-GotIt',
						});
						break;
					case 'PLAY_LIMIT_REACHED':
						message = formatMessage({
							id: 'bcs-Championship-With-Awards-Plays-Limit-Error-Handler',
						});
						button = formatMessage({
							id: 'bcs-Common-GotIt',
						});
						break;
					default:
						message = parsedError.message;
						break;
				}

				if (parsedError.status === 401) {
					router.push('/login').then(() => {
						dispatch(
							showToast({
								message,
								type: 'warning',
								title: formatMessage({ id: 'bcs-Common-Oops' }),
							})
						);
					});
				} else {
					dispatch(
						showToast({
							message,
							type: 'warning',
							title: formatMessage({ id: 'bcs-Common-Oops' }),
							buttonText: button ? button : '',
						})
					);
				}
			})
			.finally(() => {
				setIsRegisterLoading(false);
			});
	};

	const onClickViewButton = () => {
		if (gamyType === 'PLAY_NOW_TEMPLATE') {
			router.push(`/championships/${tournamentId}/view`);
		} else {
			router.push(`/tournaments/${tournamentId}/view`);
		}
	};

	const onPlayNow = (championshipId: string) => {
		if (!currentUser) {
			dispatch(updateVerificationStatus('Unknown User'));
			dispatch(updateSwitchToPage('Create Account'));
			return;
		}
		setIsRequestSent(true);

		axios
			.post('/tournaments/play-now', { championshipId })
			.then((response) => {
				const { id, isPreviouslyCreated } = response.data;

				if (isPreviouslyCreated === false) {
					router.push(`/tournaments/${id}/play`);
				} else {
					dispatch(togglePlayNowModal({ id, isPreviouslyCreated }));
				}
			})
			.catch((error) => {
				if (error.response.status === 404) {
					router.push('/404');
				}

				if (error.response.status === 403) {
					dispatch(
						showToast({
							message: formatMessage({ id: 'bcs-Play-Now-You-Can-Not-Start-Gamy' }),
							type: 'warning',
							title: formatMessage({ id: 'bcs-Common-Oops' }),
							buttonText: formatMessage({
								id: 'bcs-Common-GotIt',
							}),
						})
					);
				}
				if (error.response.status === 422) {
					if (error.response.data.code === 'PLAY_LIMIT_REACHED') {
						dispatch(
							showToast({
								message: formatMessage({ id: 'bcs-Play-Now-With-Awards-Plays-Limit-Error-Handler' }),
								type: 'warning',
								title: formatMessage({ id: 'bcs-Common-Oops' }),
								buttonText: formatMessage({
									id: 'bcs-Common-GotIt',
								}),
							})
						);
					}
					if (error.response.data.code === 'NO_PERMISSION') {
						dispatch(
							showToast({
								message: formatMessage({ id: 'bcs-Registration-No-Permission' }),
								type: 'warning',
								title: formatMessage({ id: 'bcs-Common-Oops' }),
								buttonText: formatMessage({
									id: 'bcs-Common-GotIt',
								}),
							})
						);
					}
				}

				if (error.response.status === 401) {
					router.push('/login').then(() => {
						dispatch(
							showToast({
								message: error.response.data.message,
								type: 'warning',
								title: formatMessage({ id: 'bcs-Common-Oops' }),
							})
						);
					});
				}
			})
			.finally(() => {
				setIsRequestSent(false);
			});
	};

	const onClickStartPlayAgainButton = () => {
		if (championshipStatusId === 'RUNNING' && championshipId) {
			onPlayNow(championshipId);
		}
	};

	const onClickStartButton = () => {
		onPlayNow(tournamentId);
	};

	const onClickViewLatestActiveCopy = () => {
		router.push(`/tournaments/${latestActiveCopyId}/view`);
	};

	const getTypeOfTournamentButtonTextStyle = () => {
		if (!isTournamentView) {
			return 'lg:min-h-[50px] w-full sm:h-[50px] h-10 sm:rounded-2xl rounded-[10px] sm:py-4 s:px-6 px-2.5 xs:text-mxsm text-xs';
		}

		return 'w-full min-h-[56px] h-[56px] rounded-2xl py-4 sm:px-6 s:px-2.5 px-1.5 sm:text-mxsm text-xsm';
	};

	const getTypeOfTournamentTextStyle = () => {
		if (!isTournamentView) {
			return 'sm:w-full min-h-10 xl:min-h-[50px] h-10';
		}
		return 'w-full xl:min-h-10 xl:h-10 min-h-[35px] h-[35px]';
	};

	const onClickJoin = () => {
		router.push(`/tournaments/${tournamentId}/play`);
	};

	if (championshipStatusId === 'UNKNOWN' && gamyType === 'PLAY_NOW_COPY') {
		return null;
	}

	if (
		gamyType === 'PLAY_NOW_COPY' &&
		championshipStatusId === 'RUNNING' &&
		(tournamentStatusId === 'FINISHED' ||
			tournamentStatusId === 'RUNNING' ||
			tournamentStatusId === 'REGISTERING') &&
		!isParticipantRegistered
	) {
		return (
			<button
				onClick={onClickStartPlayAgainButton}
				type='button'
				className={classNames(
					`transition-colors
				font-medium flex justify-center items-center ${getTypeOfTournamentButtonTextStyle()} 
				focus:outline-none`,
					{
						'bg-main-500 text-white': isTournamentView,
						'hover:bg-main-600': isTournamentView && !isMobile,
						'active:bg-main-600': isTournamentView && true,
					}
				)}
				data-testid='startButtonInCopy'
				disabled={isRequestSent}
			>
				{isRequestSent ? (
					<LoadingIndicator contained={true} size={32} />
				) : (
					formatMessage({ id: 'bcs-Tournament-Play-Now' })
				)}
			</button>
		);
	}

	if (gamyType === 'PLAY_NOW_TEMPLATE' && tournamentStatusId === 'RUNNING') {
		return (
			<button
				onClick={onClickStartButton}
				type='button'
				className={classNames(
					`transition-colors
				font-medium flex justify-center items-center ${getTypeOfTournamentButtonTextStyle()} 
				focus:outline-none `,
					{
						'bg-brightGray-200 text-dark-500': !isTournamentView,
						'hover:bg-main-500 hover:text-white': !isTournamentView && !isMobile,
						'active:bg-main-500 active:text-white': !isTournamentView && true,
					}
				)}
				data-testid='startButton'
				disabled={isRequestSent}
			>
				{isRequestSent ? (
					<LoadingIndicator contained={true} size={32} />
				) : (
					formatMessage({ id: 'bcs-Tournament-Play-Now' })
				)}
			</button>
		);
	}

	if (
		(gamyType === 'PLAY_NOW_TEMPLATE' && tournamentStatusId === 'FINISHED') ||
		(gamyType === 'RECURRING' && tournamentStatusId === 'FINISHED') ||
		(gamyType === 'RECURRING_COPY' && tournamentStatusId === 'FINISHED' && latestActiveCopyId === tournamentId) ||
		(gamyType === 'PLAY_NOW_COPY' &&
			(championshipStatusId === 'FINISHED_VISIBLE' || championshipStatusId === 'FINISHED_HIDDEN')) ||
		(gamyType === 'ONE_TIME' && (tournamentStatusId === 'FINISHED' || tournamentStatusId === 'REWARD_CALC'))
	) {
		return (
			<div
				className={classNames(`flex font-medium items-center ${getTypeOfTournamentTextStyle()}`, {
					'sm:w-full sm:h-[50px] h-10': !isTournamentView,
					'text-white bg-razzmatazz-700 py-3 pl-5 w-full lg:rounded-[3px]': isTournamentView,
					'text-backgroundGray-400': theme === 'light' && !isTournamentView,
					'text-white': theme === 'dark' && !isTournamentView,
				})}
			>
				{!isTournamentView ? <IconClock diameter='19' view={'TOURNAMENT_BOX'} /> : <IconClockViewPage />}
				<p className='xs:text-sm text-xs h-auto xs:ml-2 ml-[0.3125rem]' data-testid='tournamentEndedMessage'>
					{formatMessage({ id: 'bcs-Tournaments-GamyHasEnded' })}
				</p>
			</div>
		);
	}

	if (tournamentStatusId === 'ACTIVE') {
		if (isTournamentView) {
			return null;
		}

		return (
			<button
				onClick={() => onClickViewButton()}
				type='button'
				className={classNames(
					`bg-brightGray-200 text-dark-500 transition-colors
				font-medium 2xs:px-0 xs:px-6 ${getTypeOfTournamentButtonTextStyle()} 
				focus:outline-none`,
					{
						'hover:bg-main-500 hover:text-white': !isMobile,
						'active:bg-main-500 active:text-white': true,
					}
				)}
				data-testid='viewBtn'
			>
				{formatMessage({ id: 'bcs-Tournaments-ListingView' })}
			</button>
		);
	}

	if (
		latestActiveCopyId !== null &&
		latestActiveCopyId !== tournamentId &&
		(tournamentStatusId === 'FINISHED' || tournamentStatusId === 'CANCELED') &&
		gamyType !== 'PLAY_NOW_COPY' &&
		gamyType !== 'PLAY_NOW_TEMPLATE'
	) {
		return (
			<button
				onClick={() => onClickViewLatestActiveCopy()}
				type='button'
				className={`bg-main-500 text-white hover:bg-main-600 ${getTypeOfTournamentButtonTextStyle()} font-bold`}
				data-testid='viewBtn'
			>
				{formatMessage({ id: 'bcs-Tournaments-View-Active-Gamy' })}
			</button>
		);
	}

	if (tournamentStatusId === 'REGISTERING' && !isParticipantRegistered) {
		if (isMaxParticipantsLimit) {
			return (
				<div className={`flex items-center w-full ${getTypeOfTournamentTextStyle()}`}>
					<TournamentMaxParticipants isTournamentView={isTournamentView} typeTournament={typeTournament} />
				</div>
			);
		}

		return (
			<>
				<button
					onClick={onClickRegistrationButton}
					disabled={isRegisterLoading}
					type='button'
					className={classNames(
						`tournament-register-button flex justify-center select-none items-center transition-colors font-medium cursor-pointer disabled:bg-gray-200 disabled:text-black rounded-2xl py-4 ${getTypeOfTournamentButtonTextStyle()}`,
						{
							'bg-main-500 text-white': isTournamentView,
							'hover:bg-main-600': isTournamentView && !isMobile,
							'active:bg-main-600': isTournamentView && true,
							'bg-brightGray-200 text-dark-500': !isTournamentView,
							'hover:bg-main-500 hover:text-white': !isTournamentView && !isMobile,
							'active:bg-main-500 active:text-white': !isTournamentView && true,
							'py-2': isRegisterLoading,
							'sm:py-4': !isRegisterLoading,
						}
					)}
					data-testid='registerBtn'
				>
					{tournamentAccess === 'QR_SCAN' && !isTournamentView ? (
						<div className='xs:mr-2.5 mr-[0.3125rem] icon-lock-container'>{<IconLockTournaments />}</div>
					) : tournamentAccess === 'QR_SCAN' && isTournamentView ? (
						<div className='mr-2.5 tournament-register-view-button'>{<IconLock />}</div>
					) : null}

					{isRegisterLoading ? (
						<LoadingIndicator contained={true} size={32} />
					) : (
						<p>{formatMessage({ id: 'bcs-Tournaments-ListingRegister' })}</p>
					)}
				</button>
				{isCodeModalVisible ? (
					<RegistrationTournamentModal
						tournamentId={tournamentId}
						tournamentName={tournamentName}
						onClose={() => setIsCodeModalVisible(false)}
					/>
				) : null}
			</>
		);
	}

	if (
		(tournamentStatusId === 'READY' ||
			tournamentStatusId === 'STARTING' ||
			tournamentStatusId === 'RUNNING' ||
			tournamentStatusId === 'REGISTERING') &&
		gamyType !== 'PLAY_NOW_TEMPLATE'
	) {
		if ((championshipStatusId === 'RUNNING' || championshipStatusId === 'UNKNOWN') && !isParticipantRegistered) {
			return (
				<div
					className={classNames('flex font-medium items-center py-3 px-5 xl:h-10 h-[35px]', {
						'text-backgroundGray-400': !isTournamentView,
						'text-white bg-backgroundGray-400 lg:rounded-[3px]': isTournamentView,
					})}
				>
					{!isTournamentView ? <IconClock diameter='19' view={'TOURNAMENT_BOX'} /> : <IconClockViewPage />}
					<p className='text-sm ml-2' data-testid='registrationEndMessage'>
						{formatMessage({
							id: 'bcs-Tournaments-ListingRegistrationEnded',
						})}
					</p>
				</div>
			);
		}

		if (isParticipantRegistered) {
			return (
				<button
					onClick={onClickJoin}
					type='button'
					className={` ${getTypeOfTournamentButtonTextStyle()} w-full text-white text-mxsm transition-colors font-medium focus:outline-none `}
					style={{ backgroundColor: '#06CB84' }}
					data-testid='joinBtn'
				>
					{formatMessage({ id: 'bcs-Tournaments-ListingJoin' })}
				</button>
			);
		}
	}

	if (tournamentStatusId === 'CANCELED') {
		return (
			<div
				className={classNames(`flex font-medium items-center`, {
					'text-backgroundGray-400 sm:w-full sm:h-[50px] h-10': !isTournamentView,
					'text-white bg-razzmatazz-700 py-3 pl-5 w-full xl:h-10 h-[35px] lg:rounded-[3px]': isTournamentView,
				})}
			>
				<IconCanceled />

				<p className='text-sm ml-2' data-testid='oneTimeGamyCanceledMessage'>
					{formatMessage({ id: 'bcs-Tournaments-GamyCanceled' })}
				</p>
			</div>
		);
	}

	return null;
};

export default TournamentRegistrationButton;

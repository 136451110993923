import React from 'react';

const IconLockTournaments = () => {
	return (
		<>
			<svg
				width='11'
				height='15'
				viewBox='0 0 11 15'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
				className='xs:flex hidden'
			>
				<g id='Group 7139'>
					<path
						id='Vector'
						d='M5.5 11.4286C5.86467 11.4286 6.21441 11.2781 6.47227 11.0102C6.73013 10.7422 6.875 10.3789 6.875 10C6.875 9.20714 6.25625 8.57143 5.5 8.57143C5.13533 8.57143 4.78559 8.72194 4.52773 8.98985C4.26987 9.25776 4.125 9.62112 4.125 10C4.125 10.3789 4.26987 10.7422 4.52773 11.0102C4.78559 11.2781 5.13533 11.4286 5.5 11.4286ZM9.625 5C9.98967 5 10.3394 5.15051 10.5973 5.41842C10.8551 5.68633 11 6.04969 11 6.42857V13.5714C11 13.9503 10.8551 14.3137 10.5973 14.5816C10.3394 14.8495 9.98967 15 9.625 15H1.375C1.01033 15 0.660591 14.8495 0.402728 14.5816C0.144866 14.3137 0 13.9503 0 13.5714V6.42857C0 5.63571 0.61875 5 1.375 5H2.0625V3.57143C2.0625 2.62423 2.42466 1.71582 3.06932 1.04605C3.71398 0.376274 4.58832 0 5.5 0C5.95142 0 6.39842 0.0923778 6.81547 0.271859C7.23253 0.45134 7.61148 0.714409 7.93068 1.04605C8.24988 1.37769 8.50309 1.7714 8.67584 2.2047C8.84859 2.63801 8.9375 3.10242 8.9375 3.57143V5H9.625ZM5.5 1.42857C4.95299 1.42857 4.42839 1.65434 4.04159 2.0562C3.6548 2.45806 3.4375 3.00311 3.4375 3.57143V5H7.5625V3.57143C7.5625 3.00311 7.3452 2.45806 6.95841 2.0562C6.57161 1.65434 6.04701 1.42857 5.5 1.42857Z'
						fill='#11142D'
					/>
				</g>
			</svg>
			<svg
				width='6'
				height='8'
				viewBox='0 0 6 8'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
				className='xs:hidden flex'
			>
				<path
					d='M3 6.09524C3.19891 6.09524 3.38968 6.01497 3.53033 5.87208C3.67098 5.7292 3.75 5.5354 3.75 5.33333C3.75 4.91048 3.4125 4.57143 3 4.57143C2.80109 4.57143 2.61032 4.6517 2.46967 4.79459C2.32902 4.93747 2.25 5.13126 2.25 5.33333C2.25 5.5354 2.32902 5.7292 2.46967 5.87208C2.61032 6.01497 2.80109 6.09524 3 6.09524ZM5.25 2.66667C5.44891 2.66667 5.63968 2.74694 5.78033 2.88982C5.92098 3.03271 6 3.2265 6 3.42857V7.2381C6 7.44017 5.92098 7.63396 5.78033 7.77684C5.63968 7.91973 5.44891 8 5.25 8H0.75C0.551088 8 0.360322 7.91973 0.21967 7.77684C0.0790176 7.63396 0 7.44017 0 7.2381V3.42857C0 3.00571 0.3375 2.66667 0.75 2.66667H1.125V1.90476C1.125 1.39959 1.32254 0.915104 1.67417 0.557892C2.02581 0.20068 2.50272 0 3 0C3.24623 0 3.49005 0.0492681 3.71753 0.144991C3.94502 0.240715 4.15172 0.381018 4.32583 0.557892C4.49994 0.734765 4.63805 0.944745 4.73227 1.17584C4.8265 1.40694 4.875 1.65463 4.875 1.90476V2.66667H5.25ZM3 0.761905C2.70163 0.761905 2.41548 0.882313 2.2045 1.09664C1.99353 1.31097 1.875 1.60166 1.875 1.90476V2.66667H4.125V1.90476C4.125 1.60166 4.00647 1.31097 3.7955 1.09664C3.58452 0.882313 3.29837 0.761905 3 0.761905Z'
					fill='#11142D'
				/>
			</svg>
		</>
	);
};

export default IconLockTournaments;

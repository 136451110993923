import React from 'react';

const IconMaxParticipantsReachedTournamentBox = () => {
	return (
		<>
			<svg
				className='hidden sm:block'
				width='26'
				height='26'
				viewBox='0 0 26 26'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
			>
				<path
					d='M11.7 9.1H14.3V6.5H11.7M13 23.4C7.267 23.4 2.6 18.733 2.6 13C2.6 7.267 7.267 2.6 13 2.6C18.733 2.6 23.4 7.267 23.4 13C23.4 18.733 18.733 23.4 13 23.4ZM13 0C11.2928 0 9.60235 0.336255 8.02512 0.989566C6.44788 1.64288 5.01477 2.60045 3.80761 3.80761C1.36964 6.24558 0 9.55219 0 13C0 16.4478 1.36964 19.7544 3.80761 22.1924C5.01477 23.3995 6.44788 24.3571 8.02512 25.0104C9.60235 25.6637 11.2928 26 13 26C16.4478 26 19.7544 24.6304 22.1924 22.1924C24.6304 19.7544 26 16.4478 26 13C26 11.2928 25.6637 9.60235 25.0104 8.02512C24.3571 6.44788 23.3995 5.01477 22.1924 3.80761C20.9852 2.60045 19.5521 1.64288 17.9749 0.989566C16.3977 0.336255 14.7072 0 13 0ZM11.7 19.5H14.3V11.7H11.7V19.5Z'
					fill='#808191'
				/>
			</svg>
			<svg
				className='sm:hidden'
				width='19'
				height='19'
				viewBox='0 0 19 19'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
			>
				<path
					d='M8.55 6.65H10.45V4.75H8.55M9.5 17.1C5.3105 17.1 1.9 13.6895 1.9 9.5C1.9 5.3105 5.3105 1.9 9.5 1.9C13.6895 1.9 17.1 5.3105 17.1 9.5C17.1 13.6895 13.6895 17.1 9.5 17.1ZM9.5 0C8.25244 0 7.0171 0.245725 5.86451 0.723144C4.71191 1.20056 3.66464 1.90033 2.78249 2.78249C1.00089 4.56408 0 6.98044 0 9.5C0 12.0196 1.00089 14.4359 2.78249 16.2175C3.66464 17.0997 4.71191 17.7994 5.86451 18.2769C7.0171 18.7543 8.25244 19 9.5 19C12.0196 19 14.4359 17.9991 16.2175 16.2175C17.9991 14.4359 19 12.0196 19 9.5C19 8.25244 18.7543 7.0171 18.2769 5.86451C17.7994 4.71191 17.0997 3.66464 16.2175 2.78249C15.3354 1.90033 14.2881 1.20056 13.1355 0.723144C11.9829 0.245725 10.7476 0 9.5 0M8.55 14.25H10.45V8.55H8.55V14.25Z'
					fill='#808191'
				/>
			</svg>
		</>
	);
};

export default IconMaxParticipantsReachedTournamentBox;

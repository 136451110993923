import React from 'react';

const IconClockViewPage = () => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='15' height='15' viewBox='0 0 15 15' fill='none'>
			<path
				d='M7.5 0C6.01664 0 4.56659 0.439867 3.33323 1.26398C2.09986 2.08809 1.13856 3.25943 0.570907 4.62987C0.00324964 6.00032 -0.145275 7.50832 0.144114 8.96318C0.433503 10.418 1.14781 11.7544 2.1967 12.8033C3.2456 13.8522 4.58197 14.5665 6.03683 14.8559C7.49168 15.1453 8.99968 14.9968 10.3701 14.4291C11.7406 13.8614 12.9119 12.9001 13.736 11.6668C14.5601 10.4334 15 8.98336 15 7.5C14.9979 5.51154 14.207 3.60513 12.8009 2.19907C11.3949 0.793017 9.48847 0.00215068 7.5 0V0ZM11.1606 9.69687C11.0717 9.83485 10.9335 9.93375 10.7742 9.97345C10.6149 10.0132 10.4464 9.99069 10.3031 9.91062L7.17813 8.03562C7.08862 7.97691 7.01442 7.89768 6.9617 7.80452C6.90897 7.71136 6.87925 7.60696 6.875 7.5V3.75C6.875 3.58424 6.94085 3.42527 7.05806 3.30806C7.17527 3.19085 7.33424 3.125 7.5 3.125C7.66576 3.125 7.82473 3.19085 7.94194 3.30806C8.05915 3.42527 8.125 3.58424 8.125 3.75V7.14625L10.9469 8.83937C11.0889 8.92476 11.1912 9.06306 11.2313 9.22386C11.2714 9.38467 11.246 9.55481 11.1606 9.69687Z'
				fill='white'
			/>
		</svg>
	);
};

export default IconClockViewPage;

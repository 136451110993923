import React from 'react';

const IconCanceled = () => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='15' height='15' viewBox='0 0 15 15' fill='none'>
			<rect x='3' y='3' width='9' height='9' fill='#DF4047' />
			<path
				d='M7.50004 0C5.51183 0.00281057 3.60566 0.795859 2.19971 2.20554C0.793755 3.61522 0.00283056 5.52641 0 7.51993C0.411588 17.4952 14.5886 17.4916 15 7.51993C14.9972 5.52645 14.2062 3.61522 12.8003 2.20554C11.3943 0.795859 9.48821 0.00283807 7.49996 0H7.50004ZM11.0931 10.3809C11.2852 10.5877 11.2795 10.9098 11.0803 11.1097C10.881 11.3094 10.5597 11.3151 10.3535 11.1225L7.50007 8.26144L4.64662 11.1225C4.44043 11.3151 4.11918 11.3094 3.91984 11.1097C3.72066 10.9098 3.7149 10.5876 3.90707 10.3809L6.76052 7.51993L3.90707 4.65892C3.8032 4.56179 3.74309 4.42657 3.74059 4.28433C3.7381 4.14192 3.79339 4.00467 3.89384 3.90412C3.99413 3.8034 4.13101 3.74797 4.27304 3.75047C4.41491 3.75297 4.54977 3.81324 4.64664 3.91739L7.50009 6.7784L10.3535 3.91739C10.5597 3.72471 10.881 3.73048 11.0803 3.93019C11.2795 4.13005 11.2853 4.4522 11.0931 4.6589L8.23963 7.51991L11.0931 10.3809Z'
				fill='white'
			/>
		</svg>
	);
};

export default IconCanceled;

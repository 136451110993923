import React from 'react';

const IconMaxParticipantsReachedView = () => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='15' height='15' viewBox='0 0 15 15' fill='none'>
			<path
				d='M6.75 5.25H8.25V3.75H6.75M7.5 13.5C4.1925 13.5 1.5 10.8075 1.5 7.5C1.5 4.1925 4.1925 1.5 7.5 1.5C10.8075 1.5 13.5 4.1925 13.5 7.5C13.5 10.8075 10.8075 13.5 7.5 13.5ZM7.5 0C6.51509 0 5.53982 0.193993 4.62987 0.570904C3.71993 0.947814 2.89314 1.50026 2.1967 2.1967C0.790176 3.60322 0 5.51088 0 7.5C0 9.48912 0.790176 11.3968 2.1967 12.8033C2.89314 13.4997 3.71993 14.0522 4.62987 14.4291C5.53982 14.806 6.51509 15 7.5 15C9.48912 15 11.3968 14.2098 12.8033 12.8033C14.2098 11.3968 15 9.48912 15 7.5C15 6.51509 14.806 5.53982 14.4291 4.62987C14.0522 3.71993 13.4997 2.89314 12.8033 2.1967C12.1069 1.50026 11.2801 0.947814 10.3701 0.570904C9.46018 0.193993 8.48491 0 7.5 0ZM6.75 11.25H8.25V6.75H6.75V11.25Z'
				fill='white'
			/>
		</svg>
	);
};

export default IconMaxParticipantsReachedView;
